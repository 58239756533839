//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import NavSubUsecases from '../../components/nav/navSubUsecases'
import FeatureQuad from '../../components/marketing/featureQuad'
import AlternatingCallout from '../../components/marketing/alternatingCallout'
import BenefitsBoomerang from '../../components/marketing/benefitsBoomerang'

const useCaseData = [
  {
    title: 'Increase customer response rate with visual marketing',
    titleWeight: 'medium',
    titleColor: 'white-1',
    description:
      'Drive action from travel customers with offers, content marketing and more',
    descriptionColor: 'white-3',
    image: 'usecaseTravel',
    iconShow: true,
    iconContext: 'hotelbuilding',
    // iconColor: 'white',
    iconfill: 'white',
    iconcolor: 'transparent',
    // lineClass: 'green-1',
    // bgcolor: 'dkgreen-8',
    // border: 'border-top-2-dkgreen-1',
    bggradient: 'bali',
    expandcol: 5,
  },
]
const featureData = {
  title: 'Drive better ROI from your travel marketing spend',
  titlecolor: 'black-4',
  mode: 'triovertical',
  bgcolor: 'grayblue-6',
  rowdata: [
    {
      // rowbgcolor: "green",
      rowdata: [
        {
          title: 'Image-rich promotions and offers',
          titlecolor: 'blue-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'blue-1',
          iconname: 'tag',
          iconcolor: 'blue',
          path: '/platform/offers-promotions/',
          description:
            'Stay in touch with travel customers and drive repeat visits with image-rich, easily customizable promotions and offers ',
        },
        {
          title: 'Content marketing',
          titlecolor: 'green-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'green-1',
          iconname: 'newspaper',
          iconcolor: 'green',
          path: '/platform/content-marketing/',
          description:
            'Add visual content marketing to drive better customer engagement — and deliver lifestyle content through an easy drag-and-drop creator',
        },
        {
          title: 'Travel reviews',
          titlecolor: 'dkgreen-1',
          desccolor: 'black-1',
          colbgcolor: 'white-1',
          colbordercolor: 'dkgreen-1',
          iconname: 'megaphone',
          iconcolor: 'dkgreen',
          path: '/platform/review-management/',
          description:
            'Encourage positive customer reviews of your establishment on sites like Google, Tripadvisor and Yelp by customizing a visual template',
        },
      ],
    },
  ],
}
const About = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Travel and Hospitality - Visual Content Marketing"
      meta={[
        {
          name: 'description',
          content:
            'Learn about how Storylava can help you drive more revenue with visual content marketing for your travel and hospitality business',
        },
      ]}
    />

    <NavSubUsecases />

    <div class="container container-page container-about-sub">
      <div class="row">
        <div class="col-xl-10 d-flex flex-column justify-content-center text-center text-lg-left">
          <h1 class="display-2 font-weight-medium sky-1">
            Connect with your travel customers on social
          </h1>
          <p class="display-4">
            Stay close to your travel customers with visual offers, promotions,
            lifestyle content marketing and more
          </p>
        </div>
      </div>
    </div>

    <AlternatingCallout featureData={useCaseData} />

    {/* SELECTED FEATURES */}
    <FeatureQuad featureData={featureData} />
    {/* END SELECTED FEATURES */}
  </Layout>
)

export default About
